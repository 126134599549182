import React from 'react'
import { graphql, Link } from 'gatsby'
// import Helmet from 'react-helmet'
import { StaticImage } from 'gatsby-plugin-image'
// import BackgroundImage from 'gatsby-background-image'
import Layout from '../components/Layout/index'
import Pagina from '../components/Pagina'

// Styles
// import './styles/servicos.scss'

const FormasDePagamento = ({ data }) => {
  const breadC = {
    visible: true,
    items: [
      { path: '/', name: 'Institucional' },
      { path: '/formas-de-pagamento/', name: 'Formas de Pagamento' }
    ]
  }

  return (
    <Layout breadCrumb={breadC} >
      <Pagina pagina={data.pagina} />
      <section className="container mt-4 pb-5">
        <h1 className="mb-4">Formas de Pagamento</h1>
        <div className="row">
          <div className="col-xl-4 col-lg-5 mb-3 mb-lg-0">
            <StaticImage
              src="../images/formas-de-pagamento/banner-beneficios.png"
              alt="cartões de crédito 1"
              className="w-100 h-100 d-none d-md-block"
            />
          </div>
          <div className="col-xl-8 col-lg-7">
            <h3 className="mb-3">Cartão Spani Atacadista. Mais Vantagens para Você!</h3>
            <p className="mb-3">Só com ele você tem benefícios como <b>promoções</b> e <b>ofertas exclusivas</b> e até <b>45 dias para pagar sem juros!</b></p>
            <div className="row">
              <div className="col-xl-7">
                <Link to="https://oferta.vocedm.com.br/cartaospani" target='_blank'>
                  <button className="btn btn-yellow w-100 py-2 text-uppercase font-openSans-Regular"><b>Solicitar Meu Cartão Spani Atacadista</b></button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid p-0">
          <div className="container py-5">
            <h3 className="mb-3">Cartões de Crédito e Débito</h3>
             <div className="row pl-3 pr-3 justify-content-lg-start justify-content-center">
              <div className="col-auto pl-1 p-0 pt-2"><StaticImage src="../images/formas-de-pagamento/cartoes/visa.png" alt="cartão visa" className="d-md-block" width={70} height={60} /></div>
              <div className="col-auto pl-1 p-0 pt-2"><StaticImage src="../images/formas-de-pagamento/cartoes/mastercard.png" alt="cartão mastercard" className="d-md-block" width={70} height={60} /></div>
              <div className="col-auto pl-1 p-0 pt-2"><StaticImage src="../images/formas-de-pagamento/cartoes/spani.png" alt="cartão spani" className="d-md-block" width={70} height={60} /></div>
              <div className="col-auto pl-1 p-0 pt-2"><StaticImage src="../images/formas-de-pagamento/cartoes/american.png" alt="cartão american express" className="d-md-block" width={70} height={60} /></div>
              <div className="col-auto pl-1 p-0 pt-2"><StaticImage src="../images/formas-de-pagamento/cartoes/soro-cred.png" alt="cartão sorocred" className="d-md-block" width={70} height={60} /></div>
              <div className="col-auto pl-1 p-0 pt-2"><StaticImage src="../images/formas-de-pagamento/cartoes/hipercard.png" alt="cartão hipercard" className="d-md-block" width={70} height={60} /></div>
              <div className="col-auto pl-1 p-0 pt-2"><StaticImage src="../images/formas-de-pagamento/cartoes/cabal.png" alt="cartão cabal" className="d-md-block" width={70} height={60} /></div>
              <div className="col-auto pl-1 p-0 pt-2"><StaticImage src="../images/formas-de-pagamento/cartoes/elo.png" alt="cartão elo" className="d-md-block" width={70} height={60} /></div>
              <div className="col-auto pl-1 p-0 pt-2"><StaticImage src="../images/formas-de-pagamento/cartoes/diners.png" alt="cartão diners" className="d-md-block" width={70} height={60} /></div>
            </div>
          </div>
        </div>
        <div className="container py-5">
          <h3 className="mb-3">Vale Alimentação</h3>
          <div className="row pl-3 pr-3 justify-content-lg-start justify-content-center">
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/ben.png" alt="cartão ben" className="d-md-block"   width={70} height={60}/></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/plan.png" alt="cartão plan" className="d-md-block" width={70} height={60} /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/vale-card.png" alt="cartão vale-card" className="d-md-block"  width={70} height={60}/></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/greencard.png" alt="cartão greencard" className="d-md-block"   width={70} height={60}/></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/sodexo.png" alt="cartão visa sodexo" className="d-md-block" width={70} height={60}  /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/alelo.png" alt="cartão alelo" className="d-md-block" width={70} height={60}  /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/vr-alimentacao.png" alt="cartão vr-alimentacao" className="d-md-block" width={70} height={60}  /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/policard.png" alt="cartão policard" className="d-md-block" width={70} height={60}   /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/use-cred.png" alt="cartão use-cred" className="d-md-block" width={70} height={60}  /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/plantao.png" alt="cartão plantao" className="d-md-block" width={70} height={60}  /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/up-brasil.png" alt="cartão up-brasil" className="d-md-block" width={70} height={60}  /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/verocheque.png" alt="cartão verocheque" className="d-md-block" width={70} height={60}  /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/ticket-alimentacao.png" alt="cartão ticket-alimentacao" className="d-md-block" width={70} height={60}  /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/vegas.png" alt="cartão vegas" className="d-md-block" width={70} height={60}  /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/pluxee.png" alt="cartão vegas" className="d-md-block" width={70} height={60}  /></div>
            <div className="col-auto pl-1 p-0  pt-2"><StaticImage src="../images/formas-de-pagamento/va/verocard.png" alt="cartão vegas" className="d-md-block" width={70} height={60}  /></div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default FormasDePagamento

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/formas-de-pagamento/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    pageSummary
    tags
    possuiBanner
    card {
      localFile {
        publicURL
      }
    }
    desktop {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    mobile {
      localFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 414, height: 310, layout: CONSTRAINED)
        }
      }
    }
  }
}
`
